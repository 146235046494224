import React from "react";
import {Blog} from "../../models/blog";
import {useNavigate} from "react-router-dom";

export default function NewsSlide({image, content, title}: Blog) {

    const navigate = useNavigate()

  return (
      <div className="flex flex-col  space-y-4" onClick={() => navigate('/news-und-aktionen')}>
        <div
            className="rounded-2xl  group cursor-pointer relative flex justify-center items-center flex-col">
          <img className="rounded-2xl  "
               src={image}
               alt="man"/>
          <div className={"text-2xl font-bold mt-4"}>
            {title}
          </div>
        </div>
      </div>
  )
}
