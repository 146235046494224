import {createPortal} from "react-dom";
import {MdClose} from "react-icons/md";
import {Shop} from "../../models/shop";
import Button from "../Button";

export default function ShopModal(opt: {
  shop: Shop
  isOpen: boolean
  close: () => void
}) {

  if (!opt.isOpen) {
    return null
  }

  return createPortal(
      <div
          className={"fixed left-0 top-0 w-screen h-screen flex align-middle justify-center z-40 bg-black bg-opacity-25"}
          onClick={() => opt.close()}>
        <div
            className="py-8 flex align-middle justify-center h-full place-items-center">
          <div role="alert"
               className="container w-screen h-auto lg:w-1/2-screen flex align-middle justify-center">
            <div
                className="relative bg-white dark:bg-white shadow flex justify-center align-middle pt-10 pb-8 rounded-2xl w-full z-50">
              <div
                  className="flex flex-col items-center px-4 md:px-12 flex justify-center align-middle w-full"
                  onClick={(ev) => {

                    ev.stopPropagation()


                    return false
                  }}>
                <img src={opt.shop.image}
                     className={`h-24 w-auto object-fill ${opt.shop.invertImage ? 'invert' : ''}`}/>
                <p className="text-base sm:text-lg md:text-2xl font-bold md:leading-6 mt-16 text-black text-center">{opt.shop.name}</p>
                <p className="text-xs sm:text-sm leading-5 mt-2 sm:mt-4 text-center text-black w-full">
                  {opt.shop.description}
                </p>
                <h5 className={"mt-5 font-bold text-center"}>Öffnungszeiten</h5>
                <div className={"flex flex-col pt-5"}>
                  {opt.shop.openingHours.map((row, index) => {
                    return (
                        <div className={"flex flex-row flex-wrap gap-4 w-full justify-evenly"}
                             key={index}>
                          <div className={"text-right flex-grow"}>{row.day}</div>
                          <div className={"text-left"}>{row.time}</div>
                        </div>
                    )
                  })}
                </div>

                <h5 className={"mt-5 font-bold text-center"}>Kontakt</h5>
                <div className={"flex flex-col pt-5 text-center justify-center"}>
                  <div className={"flex-grow"}>
                    Telefon
                    <a href={`tel:${opt.shop.phone?.trim()}`} target={"_blank"}
                       className={"text-red-700 hover:text-red-500 ml-4"}
                       rel="noreferrer">{opt.shop.phone}</a>
                  </div>
                  <div className={"flex-grow"}>
                    Website
                    <a href={`${opt.shop.link?.trim()}`} target={"_blank"}
                       className={"text-red-700 hover:text-red-500 ml-4"}
                       rel="noreferrer">{opt.shop.link}</a>
                  </div>
                  {opt.shop.email ?
                      <div className={"flex-grow"}>
                        Email
                        <a href={`mailto:${opt.shop.email?.trim()}`} target={"_blank"}
                           className={"text-red-700 hover:text-red-500 ml-4"}
                           rel="noreferrer">{opt.shop.email}</a>
                      </div>
                      : null}
                </div>
                <div className="flex items-center mt-14 w-full justify-center"
                     onClick={() => opt?.close()}>
                  <Button onClick={() => opt.close()}>
                    schließen
                  </Button>
                </div>
              </div>
              <div
                  className="cursor-pointer absolute top-0 right-0 m-3 text-gray-800 dark:text-gray-100 transition duration-150 ease-in-out hover:bg-red-500"
                  onClick={() => opt.close()}>
                <MdClose className={"icon icon-tabler icon-tabler-x text-black"}/>
              </div>
            </div>
          </div>
        </div>
      </div>, document.body
  )
}
