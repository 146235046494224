import {ClockIcon, ShoppingBagIcon} from '@heroicons/react/24/outline';
import {AiOutlineCar} from 'react-icons/ai';

const infoFeatures: { label?: string, description?: string | JSX.Element | JSX.Element[], icon?: JSX.Element, isSeperator: boolean }[] = [
  {
    label: 'Öffnungszeiten',
    description: (
        <p>
          Montag bis Freitag: 08:00 bis 18:30 Uhr
          <br/>
          Samstag: 08:00 bis 17:00 Uhr
        </p>
    ),
    icon: <ClockIcon className={'h-12 w-12 text-primary-500'}/>,
    isSeperator: false
  },
  {isSeperator: true},
  {
    label: 'Shops',
    description:
        <p>Das City Center Braunau bietet mit seinem Shop-Angebot alles Wichtige und Nötige für den
          täglichen Bedarf und darüber hinaus.</p>,
    icon: <ShoppingBagIcon className={'h-12 w-12  text-primary-500'}/>,
    isSeperator: false
  },
  {isSeperator: true},
  {
    label: 'Parken',
    description:
        <p>Vor dem City Center Braunau stehen Ihnen genügend Parkplätze kostenlos zur
          Verfügung.</p>,
    icon: <AiOutlineCar className={'h-12 w-12 text-primary-500'}/>,
    isSeperator: false
  },
]

export default function HeaderInfoBox() {
  return (
      <div
          className={'rounded-2xl w-full bg-white py-8 xl:py-12 px-12 shadow-xl content-center gap-4 xl:gap-24 grid grid-cols-1 xl:grid-cols-3'}>
        {infoFeatures.map((feature, index) => {
          if (feature.isSeperator) {
            return null
          }
          return (
              <div
                  className={`flex flex-row items-center gap-8 w-full flex-grow justify-start aos-init aos-animate`}
                  data-aos={'fade-left'}
                  data-aos-duration={300}
                  data-aos-delay={(index - 1) * 300}
                  key={index}>
                <div className={'text-primary-500 self-center'}>
                  {feature.icon}
                </div>
                <div className={'flex flex-col text-left gap-4 h-full'}>
                  <div className={'text-secondary-500 font-bold text-xl mt-4  self-start'}>
                    {feature.label}
                  </div>
                  <div className={''}>
                    {feature.description}
                  </div>
                </div>
              </div>
          )
        })}
      </div>
  )
}
