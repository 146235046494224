import {Link, useLocation} from 'react-router-dom';
import {useMemo} from 'react';
import logo from "../assets/images/logo.png"

const nav: {
  title: string
  href: string
  type: 'menu' | 'useful-links'
}[] = [
  {
    title: 'Start',
    href: '/',
    type: 'menu'
  },
  {
    title: 'Shops',
    href: '/shops',
    type: 'menu'
  },
  {
    title: 'News & Aktionen',
    href: '/news-und-aktionen',
    type: 'menu'
  },
  {
    title: 'Kontakt',
    href: '/kontakt',
    type: 'useful-links'
  },
  {
    title: 'Impressum',
    href: '/impressum',
    type: 'useful-links'
  },
  {
    title: 'Datenschutz',
    href: '/datenschutz',
    type: 'useful-links'
  }
]

export default function Footer() {


  const location = useLocation()
  const activeLink = useMemo(() => {
    return nav.find(x => x.href === location.pathname)
  }, [location])

  return (
      <footer
          className={'max-w-screen mt-24 bg-primary-500 flex items-center content-center justify-center py-24'}>
        <div className={'container shadow-xl'}>
          <div
              className={'bg-secondary-500 rounded-2xl w-full  min-h-[40vh] sm:min-h-[33vh] -mt-36 flex flex-col items-stretch content-between justify-around py-12 sm:py-12'}
              data-aos={"fade-up"}
              data-aos-duration={600}
              data-aos-delay={0}>
            <div className={"mx-auto"}>
              <img src={logo} className={"h-16 rounded-2xl"}/>
            </div>
            <div
                className={'w-full flex flex-col sm:flex-row justify-evenly gap-12 sm:gap-24 px-12 sm:px-24 mt-14'}>
              <div className={'flex-1'}>
                <div
                    className={'font-bold text-secondary-500 text-md sm:text-xl bg-white rounded-2xl py-2 px-6'}>MENU
                </div>
                <div className={'flex flex-col gap-y-2 mt-6 items-start'}>
                  {nav.filter(x => x.type === 'menu').map((link, index) => {
                    return (
                        <Link to={link.href}
                              className={`text-white hover:decoration-2 hover:underline hover:underline-offset-2 text-sm ${activeLink === link ? 'font-bold' : 'font-normal'}`}
                              key={'menu-' + index}>
                          {link.title}
                        </Link>
                    )
                  })}
                </div>
              </div>

              <div className={'flex-1'}>
                <div
                    className={'font-bold text-secondary-500 text-sm sm:text-xl bg-white rounded-2xl py-2 px-6'}>NÜTZLICHE
                  LINKS
                </div>
                <div className={'flex flex-col gap-y-2 mt-6 items-start sm:items-end'}>
                  {nav.filter(x => x.type === 'useful-links').map((link, index) => {
                    return (
                        <Link to={link.href}
                              className={`text-white hover:decoration-2 hover:underline hover:underline-offset-2 text-sm ${activeLink === link ? 'font-bold' : 'font-normal'}`}
                              key={'useful-' + index}>
                          {link.title}
                        </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}
