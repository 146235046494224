import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import Navbar from './components/Navbar';
import Header from './components/Header';
import Start from './pages/Start';
import Footer from './components/Footer';
import Shops from './pages/Shops';
import News from './pages/News';
import Contact from './pages/Contact';
import Imprint from './pages/Imprint';
import DataPrivacy from './pages/DataPrivacy';
import ScrollToTop from './components/ScrollToTop';
import 'aos/dist/aos.css';
import Aos from 'aos';
import Halloween from "./pages/Halloween";

function App() {

    useEffect(() => {
        Aos.init({
            offset: 50
        });
        Aos.refresh()
    }, []);

    return (
        <>
            <div
                className={'flex flex-col w-full px-4 xl:px-24 justify-center items-center pt-8 lg:pt-0'}>
                <div className={'container shadow-none lg:shadow-xl p-0 lg:p-12 lg:rounded-b-2xl'}>
                    <Navbar/>
                    <Header/>
                    <ScrollToTop/>
                    <Routes>
                        <Route
                            path={'/'}
                            element={
                                <Start/>
                            }
                        />
                        <Route path={'/halloween'} element={<Halloween />} />
                        <Route
                            path={'/shops'}
                            element={
                                <Shops/>
                            }
                        />
                        <Route
                            path={'/news-und-aktionen'}
                            element={
                                <News/>
                            }
                        />
                        <Route
                            path={'/kontakt'}
                            element={
                                <Contact/>
                            }
                        />

                        <Route
                            path={'/impressum'}
                            element={
                                <Imprint/>
                            }
                        />

                        <Route
                            path={'/datenschutz'}
                            element={
                                <DataPrivacy/>
                            }
                        />
                    </Routes>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default App
