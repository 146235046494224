import {Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/solid';
import {useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from "../assets/images/logo.png"

const navLinks: {
  href: string,
  title: string
}[] = [
  {
    href: '/',
    title: 'Start'
  },
  {
    href: '/shops',
    title: 'Shops'
  },
  {
    href: '/news-und-aktionen',
    title: 'News & Aktionen'
  },
  {
    href: '/kontakt',
    title: 'Kontakt'
  }
]

export default function Navbar() {

  const [menuOpen, setMenuOpen] = useState(false)

  const location = useLocation()
  const activeMenu = useMemo(() => {
    return navLinks.find(x => x.href === location.pathname)
  }, [location])

  return (
      <div className={'flex flex-row w-full justify-between content-center items-center'}>
        <div className={`${menuOpen ? 'hidden' : 'flex'}`}>
          <img src={logo} className={"h-12 2xl:h-16 rounded-2xl"}/>
        </div>
        <div className={'flex flex-row items-center gap-24 hidden xl:flex'}>
          {navLinks.map((link, index) => {
            return (
                <Link to={link.href} title={link.title} key={link.href}
                      className={`text-xl font-black ${activeMenu === link ? 'text-primary-500' : 'text-primary-200'} hover:text-primary-500 ${link.href === '/halloween' ? 'text-orange-500' : ''}`}>
                  {link.title}
                </Link>
            )
          })}
        </div>
        <Transition show={menuOpen}
                    enter="transition-all duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-all duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={`${menuOpen ? '' : ''} flex flex-col  w-screen h-screen fixed bg-white z-50 top-0 left-0 transition align-items-center items-center xl:px-24 justify-center items-center pt-8 lg:pt-0 duration-300 touch-none `}> {/* pt-10  px-4 sm:px-1.5 md:px-1.5 lg:px-0.5 xl:px-0 */}
          <div
              className={"container shadow-none lg:shadow-xl lg:p-12 lg:pl-12 lg:rounded-b-2xl h-full px-4"}>
            <div
                className={'flex flex-row w-full justify-between place-content-center '}>
              <div className={'justify-self-center flex align-middle items-center'}>
                <img src={logo} className={"h-12 2xl:h-16 rounded-2xl"}/>
              </div>

              <button className={'cursor-pointer justify-self-end'}
                      onClick={() => setMenuOpen(false)}>
                <XMarkIcon className={'text-primary-500 h-10 w-10'}/>
              </button>
            </div>

            <div
                className={'flex flex-col items-center align-middle h-full w-full gap-4 content-start place-content-start mt-24'}>
              {navLinks.map((link, index) => {
                return (
                    <Link to={link.href} title={link.title} key={link.href}
                          onClick={() => setMenuOpen(false)}
                          className={`text-xl font-black ${activeMenu === link ? 'text-primary-500' : 'text-primary-200'} hover:text-primary-500 w-full text-center`}>
                      {link.title}
                    </Link>
                )
              })}
            </div>
          </div>
        </Transition>
        <div className={`items-center ${menuOpen ? 'hidden' : 'flex'} content-center xl:hidden`}>
          <button className={'cursor-pointer'} onClick={() => setMenuOpen(true)}>
            <Bars3Icon className={'text-primary-500 h-10 w-10'}/>
          </button>
        </div>
      </div>
  )
}
