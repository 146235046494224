import {useTitle} from "../hooks/useTitle";
import NewsEntry from "../components/news/NewsEntry";
import React from "react";
import news from "../config/news";

export default function News() {
  useTitle("News und Aktionen");

  return (
      <>
        <section id={"news"}
                 className={"py-12 lg:py-24 flex flex-col justify-center content-center items-center"}>
          <div>
            <h1 className={"text-4xl font-medium bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-0 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"}>
              Unsere News & Aktionen
            </h1>
          </div>

          {news.length <= 0 ? (
              <p
                  className={"mt-24 font-medium text-xl"}
                  data-aos={"fade-right"}
                  data-aos-duration={600}
                  data-aos-delay={100}
              >
                Zur Zeit gibt es keine News oder Aktionen
              </p>
          ) : null}
          <div
              className={
                "grid grid-cols-1 xl:grid-cols-3 mt-24 lg:mt-24 md:gap-24 place-content-center place-items-center px-4 lg:px-24"
              }
          >
            {news.map((news, index) => {
              return <NewsEntry news={news} index={index} key={index}/>;
            })}
          </div>
        </section>
      </>
  );
}
