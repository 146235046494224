import {useTitle} from '../hooks/useTitle';
import shops from '../config/shops';
import ShopEntry from '../components/shop/ShopEntry';

export default function Shops() {

    useTitle('Shops')

    return (
        <div className={'pb-12 lg:pb-24 w-full'}>
            <section id={'shops'} className={'py-12 lg:py-24 flex flex-col w-full items-center'}>
                <div data-aos={'fade-left'}
                     data-aos-duration={600} data-aos-delay={0} data-aos-once={true}>
                    <h1 className={'text-4xl font-medium bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-0 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block'}>
                        Unsere Shops
                    </h1>
                </div>

                <div className="mt-8 lg:mt-14 mx-auto px-14 lg:px-0 lg:mx-16">
                    <div
                        className="grid w-full lg:grid-cols-4 md:grid-cols-3 grid-cols-1 lg:gap-x-10 gap-x-12 lg:gap-y-10 gap-y-2 lg:px-8 space-y-12 md:space-y-0 md:px-0 place-items-center place-content-center">
                        {shops.map((shop, index) => {
                            return (
                                <ShopEntry shop={shop} key={shop.name} index={index}/>
                            )
                        })}

                    </div>
                </div>
            </section>
        </div>
    )
}
