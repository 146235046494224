import React from 'react';
import {Link} from "react-router-dom";

export type ButtonOptions = {
  link?: string
  onClick?: () => void
  children: JSX.Element | JSX.Element[] | string
}

export default function Button(opt: ButtonOptions) {

  if (!opt.link) {
    return (
        <button onClick={opt.onClick}
                className={"button border-2 px-8 py-4 rounded-2xl border-primary-500 text-primary-500 font-bold uppercase hover:bg-secondary-500 hover:text-white"}>{opt.children}</button>
    )
  }

  return (
      <Link to={opt.link}
            className={"button border-2 px-8 py-4 rounded-2xl border-primary-500 text-primary-500 font-bold uppercase hover:bg-primary-500 hover:text-white"}>{opt.children}</Link>
  )
}
