import {createPortal} from "react-dom";
import {MdClose} from "react-icons/md";
import Button from "../Button";
import {Blog} from "../../models/blog";

export default function NewsModal(opt: {
  news: Blog
  isOpen: boolean
  close: () => void
}) {

  if (!opt.isOpen) {
    return null
  }
  console.log(opt.isOpen)

  return createPortal(
      <div
          className={"fixed left-0 top-0 w-screen h-screen flex align-middle justify-center z-40 bg-black bg-opacity-25"}
          onClick={() => opt.close()}>
        <div
            className="py-8 flex align-middle justify-center h-full place-items-center">
          <div role="alert"
               className="container w-screen h-auto lg:w-1/2-screen flex align-middle justify-center">
            <div
                className="relative bg-white dark:bg-white shadow flex justify-center align-middle pt-10 pb-8 rounded-2xl w-full z-50">
              <div
                  className="flex flex-col items-center px-4 md:px-12 flex justify-center align-middle w-full"
                  onClick={(ev) => {

                    ev.stopPropagation()


                    return false
                  }}>
                <img src={opt.news.image}
                     className={`h-48 lg:h-64 rounded-2xl w-auto object-fill`}/>
                <p className="text-base sm:text-lg md:text-2xl font-bold md:leading-6 mt-8 lg:mt-16 text-black text-center">{opt.news.title}</p>
                <p className="text-xs sm:text-sm leading-5 mt-2 sm:mt-4 text-center text-black w-full">
                  {opt.news.content}
                </p>

                <div className="flex items-center mt-14 w-full justify-center"
                     onClick={() => opt?.close()}>
                  <Button onClick={() => opt.close()}>
                    schließen
                  </Button>
                </div>
              </div>
              <div
                  className="cursor-pointer absolute top-0 right-0 m-3 text-gray-800 dark:text-gray-100 transition duration-150 ease-in-out hover:bg-red-500"
                  onClick={() => opt.close()}>
                <MdClose className={"icon icon-tabler icon-tabler-x text-black"}/>
              </div>
            </div>
          </div>
        </div>
      </div>, document.body
  )
}
