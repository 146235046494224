import React from "react";
import {useTitle} from "../hooks/useTitle";

export default function Imprint() {
  useTitle("Impressum");

  return (
      <section id={"impressum"}
               className={"py-12 lg:py-24 flex flex-col justify-center content-center items-center"}>
        <div data-aos={'fade-left'}
             data-aos-duration={600} data-aos-delay={0}>
          <h1 className={"text-4xl font-medium bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-0 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"}>
            Impressum
          </h1>
        </div>
        <div className="mx-auto py-12 px-14 lg:px-0 lg:mx-16">
          <div className="w-full place-items-center place-content-center text-center">
            <p>
              Offenlegung gemäß § 25 Mediengesetz
              <br/>
              Informationen gemäß § 5 E-Commerce Gesetz
            </p>
            <p className={"mt-5"}>
              <strong>R5 Immobilien GmbH & Co KG</strong>
              <br/>
              Fleischmarkt 11<br/>
              1010 Wien
            </p>
            <p className={"mt-5"}>
              Firmenbuchgericht: Handelsgericht Wien
              <br/>
              Firmenbuchnummer: FN 616417x
            </p>
            <p className={"mt-5"}>
              <br/>
              Behörde gem. ECG (E-Commerce Gesetz): Magistratisches Bezirksamt des
              I. Bezirkes
              <br/>
            </p>

            <p className={"mt-4"}>
              <span className={"font-bold"}>Webdesign & Programmierung</span>
              <br/>
              <a href={"mailto:hi@devbox.co.at"}>hi@devbox.co.at</a>
            </p>

            <h3 className={"text-2xl font-normal mt-8"}>Hausverwaltung</h3>
            <p className={"mt-5"}>
              <strong>Immobilienverwaltung Dr. Konrad Bergen</strong>
              <br/>
              Jordangasse 7/12
              <br/>
              1010 Wien
              <br/>
              Email: <a href={"mailto:office@iv-bergen.at"}>office@iv-bergen.at</a>
            </p>

            <h3 className={"text-2xl font-normal mt-8"}>Werbung und Marketing</h3>
            <p className={"mt-5"}>
              <strong>KAPUY Communications e.U.</strong>
              <br/>
              Hauptstraße 7
              <br/>
              2721 Bad Fischau-Brunn
              <br/>
              Telefon: +43 2639 20320
              <br/>
              Email: <a href={"mailto:doris.kapuy@kapuy.at"}>doris.kapuy@kapuy.at</a><br />
              Web: <a href={"https://www.kapuy.at"} target={"_blank"} rel="noreferrer">www.kapuy.at</a>
            </p>
          </div>
        </div>
      </section>
  );
}
