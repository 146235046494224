import {useTitle} from "../hooks/useTitle";
import React from "react";

export default function Contact() {
  useTitle("Kontakt");

  return (
      <section id={"contact"}
               className={"pt-12 lg:pt-24 flex flex-col items-center content-center"}>
        <div data-aos={'fade-left'}
             data-aos-duration={600} data-aos-delay={0}>
          <h1 className={"text-4xl font-medium bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-0 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"}>
            Kontakt
          </h1>
        </div>

        <div className="w-full mt-6">
          <div className="w-full mx-auto lg:pt-12  bg-white relative">
            <div className="relative w-full shadow-2xl">
              <iframe
                  className="relative rounded-2xl"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2656.7278827401738!2d13.042280915652993!3d48.250365479232634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47743457ae2fe2f1%3A0x372b19e26284094!2sCity%20Center!5e0!3m2!1sen!2sat!4v1664288261713!5m2!1sen!2sat"
                  width="100%"
                  height={600}
                  style={{border: 0}}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
              />
              <div>
                <svg
                    className="absolute inset-x-0 w-3/4 mx-auto mb-auto cursor-pointer text-secondary-500 loc md:w-2/5 lg:inset-y-0 lg:my-auto inset-y-10"
                    width={44}
                    height={52}
                    viewBox="0 0 44 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M22 52C23.496 52 29.344 47.728 34.004 42.428C40.216 35.368 44 27.488 44 20C44 8.148 34.26 0 22 0C9.74 0 0 8.148 0 20C0 27.52 3.784 35.4 9.996 42.452C14.652 47.74 20.5 52 22 52ZM22 24.8C23.0609 24.8 24.0783 24.3786 24.8284 23.6284C25.5786 22.8783 26 21.8609 26 20.8C26 19.7391 25.5786 18.7217 24.8284 17.9716C24.0783 17.2214 23.0609 16.8 22 16.8C20.9391 16.8 19.9217 17.2214 19.1716 17.9716C18.4214 18.7217 18 19.7391 18 20.8C18 21.8609 18.4214 22.8783 19.1716 23.6284C19.9217 24.3786 20.9391 24.8 22 24.8ZM22 33.6C18.6052 33.6 15.3495 32.2514 12.949 29.851C10.5486 27.4505 9.2 24.1948 9.2 20.8C9.2 17.4052 10.5486 14.1495 12.949 11.749C15.3495 9.34857 18.6052 8 22 8C25.3948 8 28.6505 9.34857 31.051 11.749C33.4514 14.1495 34.8 17.4052 34.8 20.8C34.8 24.1948 33.4514 27.4505 31.051 29.851C28.6505 32.2514 25.3948 33.6 22 33.6Z"
                      fill="#313c5d"
                  />
                </svg>
              </div>
              <div
                  className="absolute px-8 py-8 bg-white lg:top-28 lg:left-28 md:bottom-28 bottom-28 left-8 md:left-11 lg:max-w-[460px] md:max-w-[648px] max-w-[311px] w-full lg:max-h-[550px] set">
                <div
                    className="mb-4 text-center mx-auto w-full flex justify-center">
                  <div className={"text-primary-500 font-bold"}>City Center Braunau</div>
                </div>
                <p className="text-base">
                  Sie haben Fragen rund um das City Center? Wir freuen uns über
                  Ihren Anruf oder Nachricht!
                </p>
                <p className={"font-bold mt-2"}>Immobilienverwaltung Dr. Konrad Bergen</p>
                <div className="flex mt-8 gap-x-4">
                  <svg
                      className="cursor-pointer  h-[21px] mt-1"
                      width={25}
                      height={24}
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M12.1406 10.5C12.9787 10.5 13.6581 9.82843 13.6581 9C13.6581 8.17157 12.9787 7.5 12.1406 7.5C11.3025 7.5 10.623 8.17157 10.623 9C10.623 9.82843 11.3025 10.5 12.1406 10.5Z"
                        fill="#4B5563"
                    />
                    <path
                        d="M12.1405 1.5C7.95677 1.5 4.55273 4.71797 4.55273 8.67188C4.55273 10.5548 5.42105 13.0589 7.13351 16.1147C8.50878 18.5681 10.0998 20.7867 10.9274 21.8906C11.0672 22.0792 11.25 22.2326 11.461 22.3384C11.6721 22.4441 11.9053 22.4992 12.1419 22.4992C12.3785 22.4992 12.6117 22.4441 12.8227 22.3384C13.0337 22.2326 13.2166 22.0792 13.3564 21.8906C14.1825 20.7867 15.775 18.5681 17.1502 16.1147C18.8599 13.0598 19.7282 10.5558 19.7282 8.67188C19.7282 4.71797 16.3241 1.5 12.1405 1.5ZM12.1405 12C11.5402 12 10.9534 11.8241 10.4542 11.4944C9.95513 11.1648 9.56612 10.6962 9.3364 10.1481C9.10668 9.59987 9.04657 8.99667 9.16368 8.41473C9.28079 7.83279 9.56986 7.29824 9.99432 6.87868C10.4188 6.45912 10.9596 6.1734 11.5483 6.05764C12.1371 5.94189 12.7473 6.0013 13.3019 6.22836C13.8565 6.45542 14.3305 6.83994 14.664 7.33329C14.9975 7.82664 15.1755 8.40666 15.1755 9C15.1747 9.79538 14.8546 10.5579 14.2856 11.1204C13.7166 11.6828 12.9451 11.9991 12.1405 12Z"
                        fill="#4B5563"
                    />
                  </svg>
                  <p className="text-base leading-normal text-gray-600 ">
                    Jordangasse 7/12
                    <br/>
                    1010 Wien
                  </p>
                </div>
                <div className="flex mt-8 gap-x-4">
                  <svg
                      className="cursor-pointer"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M19.875 3.75H4.125C3.42904 3.75074 2.76179 4.02755 2.26967 4.51967C1.77755 5.01179 1.50074 5.67904 1.5 6.375V17.625C1.50074 18.321 1.77755 18.9882 2.26967 19.4803C2.76179 19.9725 3.42904 20.2493 4.125 20.25H19.875C20.571 20.2493 21.2382 19.9725 21.7303 19.4803C22.2225 18.9882 22.4993 18.321 22.5 17.625V6.375C22.4993 5.67904 22.2225 5.01179 21.7303 4.51967C21.2382 4.02755 20.571 3.75074 19.875 3.75ZM19.2103 8.09203L12.4603 13.342C12.3287 13.4444 12.1667 13.4999 12 13.4999C11.8333 13.4999 11.6713 13.4444 11.5397 13.342L4.78969 8.09203C4.71038 8.03214 4.64377 7.95709 4.59372 7.87123C4.54367 7.78537 4.51118 7.69042 4.49815 7.5919C4.48511 7.49338 4.49179 7.39325 4.51778 7.29733C4.54378 7.20142 4.58858 7.11162 4.64958 7.03316C4.71058 6.95471 4.78656 6.88916 4.87312 6.84032C4.95967 6.79149 5.05506 6.76034 5.15376 6.74869C5.25245 6.73704 5.35248 6.74513 5.44802 6.77247C5.54357 6.79981 5.63272 6.84587 5.71031 6.90797L12 11.7998L18.2897 6.90797C18.447 6.7892 18.6447 6.73711 18.84 6.76296C19.0354 6.78881 19.2128 6.89053 19.3338 7.04612C19.4547 7.20171 19.5096 7.39866 19.4865 7.59439C19.4634 7.79011 19.3642 7.96888 19.2103 8.09203Z"
                        fill="#4B5563"
                    />
                  </svg>
                  <p className="text-base text-gray-600">
                    <a
                        className={"text-primary-700 hover:text-primary-200"}
                        href={"mailto:office@iv-bergen.at"}
                    >
                      office@iv-bergen.at
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style>
          {`
    @media (min-width:1024px) {
        .loc{
            left: 200px;
        }
    }
    @media (max-width:380px) {
        .set{
            width: 300px;
            left: 12px;
        }
        
    }
    `}
        </style>
      </section>
  );
}
