import bgImage from '../assets/images/bg_header.jpg'
import HeaderInfoBox from './HeaderInfoBox';
import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';

export default function Header() {

  const location = useLocation()
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {

    setShowInfo(location.pathname === '/')

  }, [location])

  return (
      <>
        <div
            className={`${showInfo ? "min-h-[66vh]" : ""} w-full mt-10 rounded-2xl relative`}>
          <div
              className={'w-full h-full filter bg-blend-multiply rounded-3xl brightness-75'}>
            <img src={bgImage}
                 className={`object-cover object-top ${showInfo ? "min-h-[45vh]" : "max-h-[45vh]"} md:min-h-0 h-full w-full rounded-3xl`}/>
          </div>
          <div
              className={`h-full w-full px-4 md:px-0   ${showInfo ? "items-start py-8 mt-4 lg:mt-24 xl:mt-36 2xl:mt-36" : "items-center"}  md:py-0 content-center md:content-start absolute top-0 left-0 flex justify-center`}>
            <div
                className={`text-white text-3xl sm:text-5xl text-center xl:text-6xl font-black ${showInfo ? "py-24 md:py-24 md:px-24 2xl:py-48" : null} px-0 sm:text-center 2xl:px-48`}
            >
                <span className={"text-white"} data-aos={'fade-left'}
                      data-aos-duration={600}
                      data-aos-delay={400}>Herzlich
              Willkommen
                    im</span>
              <br/>
              <div
                  className={`rounded-2xl text-primary-500 bg-white bg-bottom ${showInfo ? "px-4" : null} leading-snug mt-2 sm:mt-8 shadow-xl -rotate-2`}
              >City
                Center Braunau
              </div>
            </div>
          </div>
          <div
              className={`relative w-full -mt-20 px-4 md:px-16 z-30 ${showInfo ? 'flex' : 'hidden'}`}>
            <HeaderInfoBox/>
          </div>
        </div>

      </>
  )
}
