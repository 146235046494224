import {useTitle} from '../hooks/useTitle';
import halloween2 from '../assets/images/news/halloween_2023.jpg'
import React from "react";

export default function Halloween() {

    useTitle('Halloween')

    return <>

        <section id={"halloween"}
                 className={"pt-24 flex align-middle justify-center items-center content-center w-full"}>
            <div className="flex flex-col items-center justify-between overflow-hidden">
                <div
                    className="mx-4 w-auto md:mx-6 xl:ml-20  flex flex-col xl:text-left text-center justify-center items-center">
                    <div
                        className={"inline-block text-4xl font-medium"}
                        data-aos={"fade-left"}
                        data-aos-duration={600}
                        data-aos-delay={0}
                    >
                        <>
                            Kinder {" "}
                            <div
                                className={
                                    "bg-orange-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-6 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"
                                }
                            >
                                Halloween Party
                            </div>
                            {" "}
                        </>
                    </div>

                    <img src={halloween2} className={"rounded-2xl mt-8 h-96 w-auto object-cover"} />

                    <p className={"mt-12"}>Freut Ihr Euch schon auf Halloween? Wir verkürzen Eure Vorfreude darauf, denn bereits am <strong>Samstag, den 28. Oktober 2023</strong> veranstalten
                        wir im Citycenter Braunau eine <strong>Kinder-Halloween-Party</strong>.</p>

                    <p className={"xl:mt-8 mt-4"}>Wir freuen uns schon sehr auf Euch und Eure kreativen Verkleidungen und bei unserer
                        Halloween-Schminkstation könnt Ihr Euch den letzten <strong>Schliff</strong> für Euer schaurig-schönstes
                        Gesicht abholen!</p>
                    <p className={"xl:mt-8 mt-4"}>Dreht am <strong>Halloween-Glücksrad</strong> und schnappt Euch die <strong>lustigsten und schaurigsten Sofortpreise</strong> -
                        und für die <strong>schönsten, kreativsten und schaurig-schönsten Verkleidungen gibts
                            um 11 und um 14 Uhr noch einen Sonderpreis</strong> - also, bereitet Euch schon mal vor! Wir sehen uns
                        am <strong>28. Oktober ab 10 Uhr</strong> im Citycenter Braunau!</p>
                </div>
            </div>
        </section>
    </>
}
