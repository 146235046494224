import {Shop} from "../models/shop";
import DMLogo from "../assets/images/shops/dm.svg";
import KikLogo from "../assets/images/shops/kik_logo.svg";
import AdmiralLogo from "../assets/images/shops/admiral-logo.svg";
import TakkoLogo from "../assets/images/shops/takko-logo.svg";
import TwLogo from "../assets/images/shops/tw_logo.svg"
import JapanHouseLogo from "../assets/images/shops/japan_house_logo.png"
import BettyBarclayLogo from "../assets/images/shops/betty_barclay_logo.svg"
import SOliverLogo from "../assets/images/shops/soliver_logo.png"
import MittermayrLogo from "../assets/images/shops/mittermayr_logo.png"
import LibroLogo from "../assets/images/shops/libro_logo.svg"
import TediLogo from "../assets/images/shops/tedi_logo.svg"
import IntersportLogo from "../assets/images/shops/intersport_logo.svg"
import HartlauerLogo from "../assets/images/shops/logo-hartlauer.png"
import ErnstingsFamilyLogo from "../assets/images/shops/ef-logo.png"
import NKDLogo from "../assets/images/shops/NKD_logo.jpg"
import PostLogo from "../assets/images/shops/post_logo.png"
import RomaLogo from "../assets/images/shops/roma-friseurbedarf.svg"

const shops: Shop[] = [
  {
    name: "Tally Weijl",
    image: TwLogo,
    link: "https://www.tally-weijl.com/de_AT/",
    phone: "+43 7722 98239",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:30-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "09:30-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "Japan House",
    image: JapanHouseLogo,
    phone: "+43 7722 64882",
    openingHours: [
      {
        day: "Mo",
        time: "Ruhetag"
      },
      {
        day: "Di-So u. Feiert.",
        time: "11:30-14:30 u. 17:00-22:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "Betty Barclay",
    image: BettyBarclayLogo,
    phone: "+43 7722 90468",
    link: "https://www.bettybarclay.com",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "s.Oliver",
    image: SOliverLogo,
    phone: "+43 7722 90468",
    link: "https://www.soliver.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "MITTERMAYR Mode",
    image: MittermayrLogo,
    invertImage: true,
    phone: "+43 7722 62800",
    link: "https://www.mode-mittermayr.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "LIBRO",
    image: LibroLogo,
    phone: "+43 7722 64398",
    link: "https://www.libro.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:30-18:30 Uhr"
      },
      {
        day: "Sa",
        time: "08:30-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "TEDi",
    image: TediLogo,
    phone: "+43 7722 64984",
    link: "https://www.tedi.com/stores/1103/de/Oberoesterreich/Braunau/Erlachweg-12TOP-5",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "INTERSPORT",
    image: IntersportLogo,
    phone: "+43 7722 62489",
    link: "https://intersport-kaltenbrunner.at/standorte/braunau/",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "DM",
    image: DMLogo,
    description: (
        <div className={"flex flex-col"}>
          <p>
          <span className={"font-bold"}>
            {" "}
            Hier bin ich Mensch, hier kauf ich ein
          </span>
            <br/>
            <br/>
            Eine angenehme Einkaufatmosphäre, eine große Auswahl aktueller
            Drogerieprodukte sowie eine freundliche und persönliche Beratung durch
            kompetente MitarbeiterInnen: Schönheits- und gesundheitsbewusste
            Kundinnen und Kunden kommen bei dm drogerie markt auf ihre Kosten! Das
            vielfältige Sortiment in der Filiale umfasst die Bereiche Schönheit,
            Gesundheit, Ernährung, und Haushalt, aber auch Fotoausarbeitung wird
            bei dm angeboten. Neben Top-Industriemarken erhält man auch die
            beliebten dm Marken wie beispielsweise Balea, alverde NATURKOSMETIK
            oder Denkmit. Eine Auswahl, bei der man immer genau das findet, was
            man sucht: Von gesunder Ernährung bis zu einer breiten Palette an
            Naturkosmetik. Dadurch wird bewusstes Einkaufen für die ganze Familie
            möglich.{" "}
          </p>
        </div>
    ),
    link: "https://www.dm.at/store/at-261/braunau-am-inn/erlachweg-12",
    phone: "+43 7722 64468",
    email: "info@dm.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:30-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "08:30-18:00 Uhr",
      },
    ],
  },
  {
    name: "Hartlauer",
    image: HartlauerLogo,
    phone: "+43 50 6136 7907",
    link: "https://www.hartlauer.at/stores/?storeId=ERP_STORE_179",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:30-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "08:30-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "Ernsting's family",
    image: ErnstingsFamilyLogo,
    phone: "+43 1 2675502",
    link: "https://filialen.ernstings-family.at/2053/erlachweg-1-13-(top-8a)-5280-braunau-am-inn",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "NKD",
    image: NKDLogo,
    link: "https://www.nkd.com/filialen/braunau/5280/nkd-erlachweg-12",
    openingHours: [
      {
        day: "08.12.2023",
        time: "Geschlossen"
      },
      {
        day: "Mo-Fr",
        time: "09:00-18:00 Uhr"
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr"
      }
    ],
    description: ""
  },
  {
    name: "Österreichische Post AG",
    image: PostLogo,
    link: "https://www.post.at/",
    phone: "+43 800 010100",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "08:00-18:00 Uhr"
      },
    ],
    description: ""
  },
  {
    name: "KiK",
    image: KikLogo,
    description: "",
    link: "https://kik.at",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
  {
    name: "Admiral",
    image: AdmiralLogo,
    description: "",
    link: "https://www.admiral.ag/filialen/",
    email: "service@admiral.at",
    phone: "+43 7722 6291711",
    openingHours: [
      {
        day: "Mo-So",
        time: "09:00-03:00 Uhr",
      },
    ],
  },
  {
    name: "Roma Friseurbedarf",
    image: RomaLogo,
    description: "",
    link: "https://www.roma.at/",
    phone: "+43 664 9655471",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-13:00 u. 13:30-18:00 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-13:00 Uhr",
      },

    ],
  },
  {
    name: "TAKKO FASHION",
    image: TakkoLogo,
    description: "",
    link: "https://takko.com",
    phone: "+43 7722 64605",
    openingHours: [
      {
        day: "Mo-Fr",
        time: "09:00-18:30 Uhr",
      },
      {
        day: "Sa",
        time: "09:00-17:00 Uhr",
      },
    ],
  },
];

export default shops.sort((a, b) => a.name.localeCompare(b.name));
