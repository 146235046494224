import React from "react";
// eslint-disable-next-line
import "swiper/css/bundle";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import Swiper core and required modules
import SwiperCore, {Navigation} from "swiper";
import Button from "./Button";
import NewsSlide from "./news/NewsSlide";
import news from "../config/news";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function NewsOverview() {
  return (
      <section id={"news"}
               className={"pt-24 flex align-middle justify-center items-center content-center w-full"}>
        <div className="flex flex-col items-center justify-between overflow-hidden">
          <div
              className="mx-4 w-auto md:mx-6 xl:ml-20  flex flex-col xl:text-left text-center justify-center items-center">
            <div
                className={"inline-block text-4xl font-medium"}
                data-aos={"fade-left"}
                data-aos-duration={600}
                data-aos-delay={0}
            >
              <>
                Werfen Sie einen Blick auf unsere{" "}
                <div
                    className={
                      "bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-6 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"
                    }
                >
                  News
                </div>
                {" "}
                und
                <div
                    className={
                      "bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-6 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"
                    }
                >
                  Aktionen
                </div>
              </>
            </div>
            {news.length <= 0 ? (
                <p
                    className={"py-12"}
                    data-aos={"fade-right"}
                    data-aos-duration={600}
                    data-aos-delay={100}
                >
                  Zur Zeit gibt es keine News oder Aktionen
                </p>
            ) : (
                <div className="mainDiv pl-4  w-full py-12">
                  <Swiper
                      slidesPerView={"auto"}
                      grabCursor={true}
                      loopFillGroupWithBlank={false}
                      loop={false}
                      navigation={{
                        prevEl: ".swiper-button-prev",
                        nextEl: ".swiper-button-next",
                      }}
                      breakpoints={{
                        // when window width is >= 320px
                        "320": {
                          slidesPerView: "auto",
                          spaceBetween: 24,
                        },
                        // when window width is >= 480px
                        "480": {
                          slidesPerView: "auto",
                          spaceBetween: 24,
                        },
                        // when window width is >= 640px
                        "640": {
                          slidesPerView: "auto",
                          spaceBetween: 24,
                        },
                        "1024": {
                          slidesPerView: "auto",
                          spaceBetween: 32,
                        },
                        "1336": {
                          slidesPerView: 3,
                          spaceBetween: 32,
                        },
                      }}
                      className="swiper mySwiper"
                  >
                    {news.map((post, index) => {
                      return (
                          <SwiperSlide className="swiper-slide mb-10 mt-5  rounded-xl"
                                       key={index} >
                            <NewsSlide image={post.image} title={post.title} content={post.content}
                                       key={index}/>
                          </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  <div
                      className={"flex align-middle justify-center aos-animate"}
                      data-aos={"fade-up"}
                      data-aos-duration={600}
                      data-aos-delay={0}
                  >
                    <Button link={"/news-und-aktionen"}
                    >
                      zur Übersicht
                    </Button>
                  </div>
                </div>
            )}
          </div>
        </div>

        <style>
          {`
.mainDiv {
position: relative;
width: 100%
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: "";
}

.swiper-wrapper{
    display: flex;
    align-items: center;
} 

.swiper-button-next,
.swiper-button-prev {
    position: relative;
    height: 24px;
    width: 24px;
}

.swiper {
  
    height: 100%;
}
.swiper-slide:nth-child(n) {
width: 70%;
}

.swiper-slide:nth-child(2n) {
width: 70%;
}

@media (min-width: 768px) { 

.swiper-slide:nth-child(n) {
width: 35%;
}

.swiper-slide:nth-child(2n) {
width: 35%;
}
}
@media (min-width: 1024px) { 
.mainDiv {
position: relative;
height: 100%;
padding: 20px;
} 

.swiper-button-next,
.swiper-button-prev {
    position: relative;
    height: 32px;
    width: 32px;
}
.swiper-slide:nth-child(n) {
width: 40%;
}

.swiper-slide:nth-child(2n) {
width: 40%;
}
.swiper-slide:nth-child(3n) {
width: 40%;
}
}

@media (min-width: 1536px) { 
.mainDiv {
position: relative;
height: 100%;
padding: 40px;
} 

.swiper-button-next,
.swiper-button-prev {
    height: 40px;
    width: 40px;
}

.swiper-slide:nth-child(n) {
width: 30%;
}

.swiper-slide:nth-child(2n) {
width: 30%;
}
.swiper-slide:nth-child(3n) {
width: 30%;
}
.swiper-slide:nth-child(4n) {
width: 30%;
}
}   

.swiper-slide {
    
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.swiper-slide img {
    height: 300px;
    width: auto;
    object-fit: cover;
}


`}
        </style>
      </section>
  );
}
