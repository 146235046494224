import React from "react";
import shops from "../config/shops";
import Button from './Button';

export default function ShopsOverview() {
  return (
      <section id={"shops"}
               className={"pt-24 flex align-middle justify-center items-center content-center w-full"}>
        <div>
          <div
              className="text-center
mb-5 md:mb-10"
          >
            <div
                className={"inline-block text-4xl font-medium"}

                data-aos={"fade-left"}
                data-aos-duration={600}
                data-aos-delay={0}
            >
              <>
                Entdecken Sie{" "}
                <div
                    className={
                      "bg-secondary-500 decoration-8 text-white mx-4 mt-4 mb-4 md:mt-0 md:mb-0 py-2 px-4 -rotate-2 rounded-2xl decoration-red-700 font-extrabold uppercase inline-block"
                    }
                >
                  eine Vielzahl
                </div>
                {" "}
                an Shops
              </>
            </div>
          </div>
          <div
              className="pt-16 mt-12 px-15 bg-gray-50 flex flex-wrap rounded-xl gap-12 p-12 justify-center space-y-5">
            {shops
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((shop, index) => {
                  if (index >= 4) return;
                  return (
                      <div key={index}
                           className={`${
                               shop.invertImage ? "invert" : ""
                           } w-1/3 xl:w-1/5 flex justify-center items-center aos-init grayscale hover:grayscale-0`}
                      >
                        <img
                            src={shop.image}
                            className={"aos-init xl:py-12"}
                            data-aos={"fade-left"}
                            data-aos-duration={300}
                            data-aos-delay={index * 100}
                        />
                      </div>
                  );
                })}
          </div>
          <div
              className={"flex align-middle justify-center mt-10 aos-init"}
              data-aos={"fade-up"}
              data-aos-duration={300}
              data-aos-delay={300}
          >

            <Button link={"/shops"}>zur Übersicht</Button>
          </div>
        </div>
      </section>
  );
}
