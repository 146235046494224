import {Blog} from "../models/blog";

const news: Blog[] = [
    // {
    //     image: nikolo,
    //     title: 'DER NIKOLAUS KOMMT!',
    //     content: <div>
    //         <p><strong>Wann?</strong></p>
    //         <p>Samstag 02.12.2023 von 13.00 – 14.30 Uhr</p>
    //         <br/>
    //         <p>
    //             Am Samstag, 2. Dezember 2023 kommen der Nikolaus und ein Engel ins Citycenter Braunau und
    //             verteilen Geschenke an die kleinen Besucherinnen und Besucher! Und wer möchte und sich traut, kann
    //             sich mit dem Nikolaus gerne auch fotografieren lassen!</p>
    //     </div>
    // }
    ];

export default news
