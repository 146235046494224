import ShopsOverview from '../components/ShopsOverview';
import NewsOverview from '../components/NewsOverview';
import {useTitle} from '../hooks/useTitle';

export default function Start() {

  useTitle('Startseite')

  return <>
    <ShopsOverview/>
    <NewsOverview key={"news"}/>
  </>
}
